<template lang="pug">
    ul#linhaDoTempo
        li(v-for="item in items", :class="{ hasImage: item.image != null && item.image != undefined }", @click="setLinhaDoTempo(item)")
            .year
                SvgIcon(data="@svgs/timeline.svg", original)
                .spin
                    .date
                        p {{ item.decade }}
                    .img
                        img(:src="item.image", :alt="item.name").fit
            .title
                h4 {{ item.name }}
                p {{ item.description }}
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-linha-do-tempo",
    props,
    methods: {
        setLinhaDoTempo(item) {
            this.$store.commit('setLinhaDoTempo', { ativo: true, content: item })
        }
    },
    data() {
        return {
            items: [],
        }
    },
    mounted() {
		this.items = this.contents.content.map(item => ({
			...item,
			image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
		}))
	}
}
</script>

<style lang="stylus" scoped src="./LinhaDoTempo.styl"></style>
